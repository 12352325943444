import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import { computed } from 'vue'

export const useUserHasBidBlocked = () => {
  const { tokenData } = useToken()

  return computed(() => {
    return tokenData.value.status.includes('bidblock')
  })
}
